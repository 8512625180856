<template>
  <v-container>
    <h1>Delete Multiple Users</h1>
    <v-alert
      v-if="errorData.length !== 0"
      class="mb-6"
      type="warning"
      outlined
      text
    >
      Some data can't be processed:
      <ul>
        <li v-for="(error, index) in errorData" :key="index">{{ error }}</li>
      </ul>
    </v-alert>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form ref="form" @submit.prevent="submit">
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.emails"
              :disabled="loading"
              hint="Separate e-mail with line break"
              label="E-mail list"
              required
            />
            <v-btn
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import User from "@/services/user";

export default {
  name: "User.DeleteMultiple",
  data() {
    return {
      form: {
        emails: ""
      },
      loading: false,
      errorData: []
    };
  },
  async created() {
    this.setNavbarTitle("Delete Multiple User");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async submit() {
      this.loading = true;
      try {
        this.errorData = [];
        await User.deleteMultiple(this.form);

        this.$snackbar.showMessage({
          content: `Users has been deleted`,
          variant: "success"
        });
        this.$router.push({ name: "User.Index" });
      } catch (error) {
        this.errorData = error.response.data.data;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
